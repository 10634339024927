import './bootstrap';

window.addEventListener('load', function(e) {

    document.querySelectorAll(
        '.filament-forms-locale-tabs-component .filament-forms-tabs-component-button').
        forEach(function(element) {
            element.removeAttribute('x-bind:tabindex');
            element.setAttribute('tabindex', '-1');
        });

    const pinInputs = document.querySelectorAll('.access-code-form .pin');

    pinInputs.forEach(function(element, key) {
        element.addEventListener('keyup', function () {
            if (element.value) {
                if (key === pinInputs.length - 1) {

                    document.getElementById('access-code').value = [...pinInputs].map(
                        (input) => input.value).join('');

                    document.getElementById('access-code-submit-button')
                        .classList
                        .remove('invisible');

                } else {
                    pinInputs[key + 1].focus();
                }
            }
        });
    });
});

//let buttonSpan = document.querySelector('button > span')
// if (buttonSpan) {
//     buttonSpan.addEventListener('click', (e) => {
//         e.stopPropagation();
//         e.preventDefault();
//     });
// }

if (typeof Livewire !== 'undefined') {
    Livewire.on('workflow-selected', function () {
        setTimeout(function() {
            const element = document.getElementById('workflow-info')
            if (null !== element) {
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }, 500);
    });
}
